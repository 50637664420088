import React from "react"
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Helmet } from "react-helmet"
import {Link} from 'gatsby'

export default function Terms() {

    return (

        <>

        <Helmet>
            <meta charSet="utf-8" />
            <title>Terms of Service | Skillmeter</title>
        </Helmet>
                
        <Header />

        <section className="py-12 bg-gray-50 sm:py-16 lg:py-20">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl xl:text-5xl ">
              Terms of Service
            </h2>
          </div>
  
          <div className="max-w-5xl mx-auto mt-10 sm:mt-20">
            <div className="flow-root">
            <div className="-my-8 divide-y divide-gray-200">
                <div className="py-8">
                  <p className="mt-8 text-lg font-normal text-gray-600 ">
                  These Terms of Service define the terms by which you may use the www.Skillmeter.com website ("Website") and related service (the "Service"), and are an agreement between you and ALGORITHMA SAS (the "Company")(the Company, Website, and Service are collectively referenced as "we" or "us"). By using this Website and Service, you expressly acknowledge that you have read, accept, and agree to be bound by these Terms of Service. You should not use this Website and/or Service, if you do not agree to these Terms of Service.
                  </p>
                  <p className="mt-8 text-lg font-normal text-gray-600 ">
                  We may modify our Terms of Service at any time without notice or in our sole discretion, and any amendments will apply immediately. Your continued use of this Website and Service after any amendments shall signify your acceptance of the amendments. You should return to this page from time to time in order to ensure that you are aware of any changes. The <Link to='/privacy'  className='underline text-blue-600 hover:text-blue-800'>Privacy Policy</Link> posted to our Website also forms part of these Terms of Service. We may at any time modify the Privacy Policy or introduce new policies regarding the use of this Website and Service. Any reference to our Terms of Service includes all such policies.
                  </p>
                  <p className="mt-8 text-lg font-normal text-gray-600 ">
                  This Agreement applies to all visitors, registered users, and subscribers who access our Website or Service.
                  </p>

                </div>


                <div className="py-8">
                  <p className="text-2xl font-bold text-gray-900 ">
                    1. Our Service
                  </p>
                  <p className="mt-8 text-lg font-normal text-gray-600 ">
                  Our Service enables recruiters and HR personnel to efficiently measure the skills of their job candidates via custom online testing.
                  </p>
                  <p className="mt-8 text-lg font-normal text-gray-600 ">
                  When you subscribe to our Service, you will have the possibility to create your own tests that can be used to measure candidates skills. You have the freedom to create the tests for any domain, according to your needs. Once you add candidates and assign them to a specific test, a PIN code will be automatically generated. The candidates will then be able to take the test by entering a combination of PIN code and a personal information (Email or First Name or Last Name).
                  </p>
                  <p className="mt-8 text-lg font-normal text-gray-600 ">
                  Upon the completion of the test, a report with the test result will be email to the recruiter in charge.
                  </p>

                </div>
  
                <div className="py-8">
                  <p className="text-2xl font-bold text-gray-900 ">
                    2. Registration
                  </p>
                  <p className="mt-8 text-lg font-normal text-gray-600 ">
                  You do not have to register in order to visit our Website. In order to access many of the features of our Service, however, you will need to register, create an account, and become a subscriber. When you register, you will be asked to provide the following information: name of business, email address, and credit card information (including a three digit security number). You will also be asked to create a login and a site name.
                  </p>
                  <p className="mt-8 text-lg font-normal text-gray-600 ">
                  All information provided by you in your registration must be truthful and complete. We may suspend or cancel your registration without notice if we have any reason to believe that this is not the case. In addition, we may suspend or cancel your registration with us at any time without prior warning if you fail to comply with these Terms of Service, without limiting any other remedies to which we may be entitled. In addition, if we determine that you have committed fraud or engaged in any other illegal activity, we may report you to the appropriate law enforcement authorities.
                  </p>
                  <p className="mt-8 text-lg font-normal text-gray-600 ">
                  You must be 16 years old or older to register and use our Website and Service. In addition, you must be fully competent to enter into and to comply with the terms, conditions, obligations, representations, and warranties set forth in these Terms of Service. By registering and using our Website and Service, you represent and warrant that you have the right, authority, and capacity to enter into these Terms of Service and can abide by all of the terms and conditions set forth therein.
                  </p>

                </div>
  
                <div className="py-8">
                  <p className="text-2xl font-bold text-gray-900 ">
                    3. Subscription terms
                  </p>
                  <p className="mt-8 text-lg font-normal text-gray-600 ">
                  <ol className="list-latin ml-4">
                    <li>Access. We grant access to use our Services on a per user basis, so each individual recruiter is required to maintain an individual subscription to our Services.</li>
                    <li>Scope of Subscription. We grant each subscriber a non-transferable, non-exclusive license to (a) access, use, and display our Services for your business purposes; (b) to upload and store data and content into your account on our host server; and (c) to use, display, and make copies of any documentation about our Services for your internal business purposes only.</li>
                    <li>Restrictions. You may not share, rent, resell, lease, sublicense, or otherwise permit any third party to access, use, or display or Services. The Services and the underlying software platform (the "Software") contain our trade secrets and in order to protect those trade secrets, you agree not to take any action to reverse engineer, compile, translate, disassemble, copy, or create derivative works of the Software in whole or in part, nor to permit any third party to do so.</li>
                    <li>Service Packages. We make available three different service packages to our subscribers: (i) Our Standard Plan is priced at €49 ($59) per month and provides access for 5 users and 50 candidates per month; (ii) Our Premium Plan is priced at €99 ($119) per month and provides access for 10 users and 100 candidates per month, public and private tests; and (iii) Our Ultimate Plan is priced at €199 ($239) per month and provides unlimited users access, unlimited tests and candidates, public and private tests and online code challenges. We offer a free initial trial to all subscribers when they first sign up to use our Services. We accept all major credit cards for payment. All payments are non-refundable.</li>
                    <li>Subscription Period: Subscriptions commence 14 days after you first sign up for our Services and is automatically renewed every month.</li>
                    <li>Cancellation. You may cancel your subscription at any time without notice through your account profile on our Website. We also reserve the right to cancel your subscription at any time in the event that you materially breach any term or condition of our Terms of Service. All cancellations will be effective immediately. Cancellation requests through email will not be accepted.</li>
                    <li>Upgrades. In the event you upgrade your service package at any time, your upgraded service package will be effective immediately but you will not be billed the applicable service fee for the upgraded package until the commencement of your new subscription period.</li>
                    <li>Uploaded Content. You may upload to your account such content as a list of candidates (emails & other personal information), logos, photos and text ("Content"). We agree that you or your licensors will retain all right, title, and interest in the Content that you upload to your account. You grant to us (i) a non-exclusive license to privately display all such Content in your account and to privately distribute such Content to third parties that you designate; and (ii) a perpetual, non-exclusive license to store all Content on our host server and to create archived back-up copies. You expressly warrant that you own all right, title, and interest in all such Content, or in the alternative, that you have procured a valid license from the copyright owner of the Content, which authorizes you to grant sublicenses to authorize the display, distribution, and storage of such Content as set forth herein. You are solely responsible for ensuring that all Content that you upload is appropriate; legal and not in violation of any federal, state, or local law or ordinance; is not obscene or pornographic; does not defame any third party; is not threatening or harassing; does not invade anyone’s privacy; is not infringing; and is not otherwise objectionable. You acknowledge and agree that any Content that you upload to your account will be visible to us when we log into your account for any reason, including to provide support to you.</li>
                    <li>Dormant Accounts. We reserve the right to delete the dormant accounts of users who have not maintained an active subscription during the last 6 consecutive calendar months. If we delete a dormant account, all uploaded Content for that account stored on our server will also be deleted.</li>
                </ol>                    
                  </p>
                </div>
  
                <div className="py-8">
                  <p className="text-2xl font-bold text-gray-900 ">
                    4. Operation of Our Website and Service
                  </p>
                  <p className="mt-8 text-lg font-normal text-gray-600 ">
                  We use commercially reasonable efforts to maintain our Website and Service and to keep them operating on a 24 hour, 7 day a week basis, free from bugs, errors, technical problems, or defects. If, at any time in the future, we identify any bugs, errors, technical problems, or defects, then we will assign technicians to address and resolve the issue.
                  </p>
                  <p className="mt-8 text-lg font-normal text-gray-600 ">
                  If you become aware of an error, bug, or other technical problem, then you should notify us immediately of the incident and provide us with the following information:
                  </p>
                  <p className="mt-8 text-lg font-normal text-gray-600 ">
                  <ol className="list-latin ml-4">
                    <li>Description of the Incident. The specific sequence of events which generated the incident, and a full description;</li>
                    <li>Description of Error Message. The exact wording of any error messages, if applicable; and</li>
                    <li>Description of Discovery of Incident. Any special circumstances surrounding the discovery of the incident for which you are seeking technical support.</li>
                </ol>
                  </p>
                  <p className="mt-8 text-lg font-normal text-gray-600 ">
                  We cannot guarantee that your access to the Website or Service will be uninterrupted, or that the Website and Service will be available at all times. We disclaim any and all liability or responsibility for any delay, interruption, or downtime.
                </p>
                  <p className="mt-8 text-lg font-normal text-gray-600 ">
                  We use commercially reasonable efforts to ensure that our Website and Service are protected from viruses and other destructive software, but we cannot guarantee that either will at all times be free from viruses. We urge you to use reasonable care in downloading information. We can assume no responsibility for any damage to computer equipment or other property that may result from the use of the Website and Service or as a result of downloading from the Website and Service.
                  </p>

                </div>

                <div className="py-8">
                  <p className="text-2xl font-bold text-gray-900 ">
                    5. Acceptable User Policy
                  </p>
                  <p className="mt-8 text-lg font-normal text-gray-600 ">
                  Our Website and Service should only be used for lawful purposes. We specifically prohibit any other use of the Website and Service, including but not limited to the following:
                </p>
                  <p className="mt-8 text-lg font-normal text-gray-600 ">
                  <ol className="list-latin ml-4">
                    <li>Impersonation; Misrepresentation: Posting or submitting to the Website and Service any information in which you impersonate or claim to be any third party, or in which you misrepresent your affiliation with another person or entity;</li>
                    <li>Providing Unauthorized Access to the Website and Service: Disclosing or sharing your login magic-link with any third party or allowing such third party access to the protected features of the Service;</li>
                    <li>Sending Unsolicited Communications through the Service: Sending unsolicited proposals or other communications through our Service to third parties;</li>
                    <li>Objectionable Communications through the Website and Service: Posting content or initiating communications which are unlawful, libelous, abusive, obscene, discriminatory, or otherwise objectionable;</li>
                    <li>Illegal Activity: Using the Website or Service for any illegal purpose;</li>
                    <li>Disclosing Confidential Information without Permission: Sharing information or initiating communications with information you are under an obligation not to disclose;</li>
                    <li>Uploading or Sending Infringing Content: Uploading or sending infringing content through the Service;</li>
                    <li>Tampering with the Website or Service: Taking any action that disrupts, tampers with, interferes, or imposes an unreasonable burden on the Website’s or our software platform’s infrastructure, servers, data, or network or those of any third party via our Service;</li>
                    <li>Using Unauthorized Search Tools: Using or attempting to use any engine, software tool, agent, or other device or mechanism (including without limitation browsers, spiders, robots, avatars, or intelligent agents) to navigate or search the Website or platform other than generally available third party browsers (including but not limited to Internet Explorer, Firefox, and Safari);</li>
                    <li>Uploading Viruses: Uploading any content that contains software viruses or any computer code, files, or programs designed to interrupt, destroy, or limit functionality of the Website or Service, or of any computer software, hardware, or telecommunications equipment;</li>
                    <li>Infringing Intellectual Property: Deciphering, decompiling, disassembling, copying, duplicating, aggregating, or reverse engineering any of the software, content, information, or other materials comprising or in any way making up part of the Website or Service;</li>
                    <li>Mining Data: Using any means of automatically searching or mining data from the Website or Service, or in any way attempting to interfere with the proper working of the Website or Service; or</li>
                    <li>Scraping or Accessing through Automated Means: Attempting to scrape or access the Service through automated means other than an official API that we provide to you.</li>
                </ol>
                  </p>
                  <p className="mt-8 text-lg font-normal text-gray-600 ">
                  We reserve the right (but do not have the obligation) to suspend or cancel the account of any user who does not appropriately use the Website or Service. If you become aware of any inappropriate use, please notify us at our email address at <a href='mailto:support@skillmeter.com' className='underline text-blue-600 hover:text-blue-800'>support@skillmeter.com</a>.</p>

                </div>

                <div className="py-8">
                  <p className="text-2xl font-bold text-gray-900 ">
                    6. Intellectual Property
                  </p>
                  <p className="mt-8 text-lg font-normal text-gray-600 ">
                  We or our licensors shall retain all right, title, and interest in the marks, logos, code, databases, content, text, designs, photos, and other materials posted to our Website, made available through our Service, and comprising our software platform, as well as any landing pages, social media pages, or other feeds set up on behalf of our Company or our Website ( collectively "Intellectual Property"). Except as otherwise expressly stated herein, you may only display, view, and listen to the Intellectual Property and you may not reproduce, display, copy, republish, download, upload, post, transmit, publicly perform or display, distribute, create derivative works of, misappropriate, or otherwise use for any purpose any portion of our Website, the Service, the software platform, our landing pages, our social media pages, or our feeds without the express written consent of us or our licensors as appropriate. Using the Intellectual Property on any other Website or Service for any commercial purpose is expressly prohibited.
                </p>

                </div>

                <div className="py-8">
                  <p className="text-2xl font-bold text-gray-900 ">
                  7. Intellectual Property Infringement Complaints
                  </p>
                  <p className="mt-8 text-lg font-normal text-gray-600 ">
                  We respect the intellectual property rights of others. If you ever suspect that your intellectual property has been copied in any manner that constitutes intellectual property infringement, which is in any way relevant to this Website or Service, then please notify us immediately, providing the following information:
                </p>
                  <p className="mt-8 text-lg font-normal text-gray-600 ">
                  <ol class="list-latin ml-4">
                    <li>Identification of the Intellectual Property. The name of the owner of the intellectual property and a signature of the person authorized to act on behalf of the intellectual property interest, which is alleged to be infringed;</li>
                    <li>Description of Infringed Right. A description of the intellectual property right that you claim has been infringed;</li>
                    <li>Location of Infringing Item. A description of where the allegedly infringing item is located on the Website;</li>
                    <li>Contact Information. Your address, telephone number, and email address; and</li>
                    <li>Statement under Penalty of Perjury. A statement by you confirming that you are the intellectual property owner or are authorized to act on the intellectual property owner’s behalf, made under penalty of perjury.</li>
                </ol>                      
                  </p>

                </div>
                <div className="py-8">
                  <p className="text-2xl font-bold text-gray-900 ">
                    8. Security
                  </p>
                  <p className="mt-8 text-lg font-normal text-gray-600 ">
                  We have implemented commercially reasonable security measures such as encryption to protect your personal and business information; however, we cannot provide any guarantees that unauthorized third parties will never be able to defeat those measures or use your personal or business information for improper purposes. You acknowledge and agree that any personal or business information that you upload to our Service is provided at your own risk.
                  </p>

                </div>

                <div className="py-8">
                  <p className="text-2xl font-bold text-gray-900 ">
                    9. Warranty; Disclaimer
                  </p>
                  <p className="mt-8 text-lg font-normal text-gray-600 ">
                  Your use of this Website and Service shall be at your sole risk. You solely assume all risks of deciding to use our Service with a particular candidate or for a particular job position, uploading your Content to our Service, and storing your Content on our host server. You also solely assume all risks with respect to accurately entering your information into a particular proposal and manually entering in contact information regarding a particular candidate or other Content into our system.
                </p>
                  <p className="mt-8 text-lg font-normal text-gray-600 ">
                  We cannot warrant that that your access to the Website or our Service will be continuous, uninterrupted, bug-free, error-free, virus-free, free of defects, or free of technical problems, or that any functionality on our Website or Service will operate in time for you to meet any deadline. We cannot warrant that any information or Content that you upload to our Service will be completely secure and never accessible by any unintended third party, nor can we warrant that that the Content that you upload to our Service or electronically signed documents will not be lost or deleted due to a hardware or software failure, security issue, or software virus. We will, however, use reasonable care to provide uninterrupted, bug-free, error-free, secure Service, and to back-up all Content.
                </p>
                  <p className="mt-8 text-lg font-normal text-gray-600 ">
                  TO THE EXTENT PERMITTED BY LAW, WE EXPRESSLY DISCLAIM ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING THE WARRANTIES OF QUIET ENJOYMENT AND NON-INFRINGEMENT AND ANY IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE.
                </p>

                </div>

                <div className="py-8">
                  <p className="text-2xl font-bold text-gray-900 ">
                    10. Limitation of Liability; Consequential Damages
                  </p>
                  <p className="mt-8 text-lg font-normal text-gray-600 ">
                  You agree that Company, the Website, and our Service will not be liable to you for any indirect, consequential, special, punitive, or exemplary damages, arising out of or in connection with the use of this Website or Service, including but not limited to damages for loss of profits, goodwill, use, data, or other intangible losses (even if we have been advised of the possibility of such damages).
                </p>
                  <p className="mt-8 text-lg font-normal text-gray-600 ">
                  Our liability to you shall in no event exceed the total amount of subscription fees that you paid to us in the year immediately preceding any claim. Some jurisdictions do not allow the limitation of liability, so these limitations may not apply to you.                  
                  </p>

                </div>


                <div className="py-8">
                  <p className="text-2xl font-bold text-gray-900 ">
                    11. Release of Claims
                  </p>
                  <p className="mt-8 text-lg font-normal text-gray-600 ">
                  To the maximum extent permitted by applicable law, you hereby release and waive our officers, employees, independent contractors, representatives, and agents from any and all liability for claims, damages (actual and or consequential), costs and expenses (including litigation costs and attorneys’ fees) of every kind and nature arising from or in any way related to Company, our Website, or our Service.
                </p>

                </div>




                <div className="py-8">
                  <p className="text-2xl font-bold text-gray-900 ">
                    12. Indemnification
                  </p>
                  <p className="mt-8 text-lg font-normal text-gray-600 ">
                  By using our Website and Service, you agree to indemnify, defend, and hold harmless Company, Website, and Service, as well as our officers, employees, independent contractors, representatives, agents, other users, and visitors against any third party claim arising from or in any way related to your use of this Service, including but not limited to any liability or expense arising from any and all claims, demands, losses, damages, costs, expenses, suits, judgments, litigation costs, or attorney’s fees.
                </p>

                </div>

                <div className="py-8">
                  <p className="text-2xl font-bold text-gray-900 ">
                    13. Feedback; Idea Submissions
                  </p>
                  <p className="mt-8 text-lg font-normal text-gray-600 ">
                  We are pleased to hear from you and welcome your feedback about the Website and our Services. If you provide any feedback to us, you agree that all such feedback will not be subject to any obligation of confidence by us, and that we will not be liable for any use or disclosure of the feedback. Also, you agree that we may use any such feedback to make improvements to our Website and Service at our sole discretion without any obligation to you.
                </p>

                <p className="mt-8 text-lg font-normal text-gray-600 ">
                In the event that you submit any ideas to us about the Website or our Service, you grant to us an exclusive, perpetual, royalty-free, transferable, worldwide license with the right to grant sublicenses to use, display, copy, publish, republish and to incorporate into our Intellectual Property your idea.
                </p>
                </div>

                <div className="py-8">
                  <p className="text-2xl font-bold text-gray-900 ">
                    14. Miscellaneous
                  </p>
                  <p className="mt-8 text-lg font-normal text-gray-600 ">
                  We reserve the right to discontinue this Website and our Services at any time in our sole discretion. You agree that we may assign these Terms of Service without prior notice to a successor entity in the event of a merger, acquisition, or sale of all or part of our business. No waiver of any breach of the Terms of Service, no matter how long continuing or how often repeated will be deemed a waiver of any subsequent breach, nor shall any delay or omission to exercise any right, power, or privilege hereunder be deemed a waiver of such right, power, or privilege. If any section of these Terms of Service is held to be unenforceable or in conflict with the law of any jurisdiction, the validity of the remaining paragraphs shall not be affected by such holding. The meaning of that section shall be construed to the extent feasible to render the section enforceable and to give effect to the Parties’ intentions as reflected in the section. If no feasible interpretation would save such section, it is to be severed from the remainder of these Terms of Service, which are to remain in full force and effect. The Terms of Service constitute the entire agreement with you and us with respect to the subject matter set forth herein. The section headings and subheadings contained in these Terms of Service are included for convenience only and shall not limit or otherwise affect these Terms of Service.
                </p>

                </div>

                <div className="py-8">
                  <p className="text-2xl font-bold text-gray-900 ">
                    15. Contact Us
                  </p>
                  <p className="mt-8 text-lg font-normal text-gray-600 ">
                  In the event that you have any questions about these Terms of Service, or that you need further assistance with respect to the access or use of the Website or Services, please notify us at the contact information listed below:
                  <br /><br />
                  ALGORITHMA SAS<br />
                    RCS Registration Number: 882 871 775<br />
                    Address: 2 rue de la Libération, 94130 Nogent sur Marne, France<br />                    
                    Email: support@skillmeter.com
                </p>

                </div>

                <div className="py-8">
                  <p className="text-2xl font-bold text-gray-900 ">
                    16. Effective Date
                  </p>
                  <p className="mt-8 text-lg font-normal text-gray-600 ">
                  These Terms of Service were last modified on the 7th of January, 2021.
                </p>

                </div>

              </div>
            </div>
          </div>
        </div>
      </section>
  
        <Footer />
      </>
    )
}